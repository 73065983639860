import { IubendaLanguage } from "@casavo/equus/lib/types/language"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { FooterLegalInfo } from "@app/shared/types/website-pages"

import { footerPrivacyTerms, footerPrivacyTermsList, footerPrivacyTermsListItem } from "./styles.css"
import { Body } from "@casavo/habitat"

const builtInLegalLinks: Record<IubendaLanguage, { title: string; url: string }[]> = {
  en: [
    {
      title: "Privacy Policy",
      url: "/privacy-policy/",
    },
    {
      title: "Terms and Conditions",
      url: "/terms-and-conditions/",
    },
    {
      title: "Digital signature",
      url: "/digital-signature/",
    },
  ],
  es: [
    {
      title: "Política de Privacidad",
      url: "/es/politica-de-privacidad/",
    },
    {
      title: "Términos y Condiciones",
      url: "/es/terminos-y-condiciones/",
    },
    {
      title: "Firma electronica",
      url: "/es/firma-electronica/",
    },
  ],
  fr: [
    {
      title: "Politique de Confidentialité",
      url: "/fr/privacy-policy/", // politique-de-confidentialite
    },
    {
      title: "Conditions Générales",
      url: "/fr/conditions-generales/",
    },
    {
      title: "Mentions légales",
      url: "/fr/mentions-legales/",
    },
  ],
  it: [
    {
      title: "Privacy Policy",
      url: "/it/privacy-policy/",
    },
    {
      title: "Termini e Condizioni",
      url: "/it/termini-e-condizioni/",
    },
    {
      title: "Firma elettronica",
      url: "/it/firma-elettronica/",
    },
  ],
}

export const PrivacyTerms: FC = () => {
  const { lang, t } = useTranslation("footer")
  const builtInLinks = builtInLegalLinks[lang as IubendaLanguage]
  const { title, vat } = t<FooterLegalInfo>("footer:legalInfo", {}, { returnObjects: true })

  return (
    <div className={footerPrivacyTerms}>
      <ul className={footerPrivacyTermsList}>
        {builtInLinks.map(({ title, url }, index) =>
          index === 0 ? (
            <li key={index} className={footerPrivacyTermsListItem}>
              <Body noMargin size="s">
                <a
                  aria-label={title}
                  href={`/pdf/privacy-policy-${lang}.pdf`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {title}
                </a>
              </Body>
            </li>
          ) : (
            <li key={index} className={footerPrivacyTermsListItem}>
              <Body noMargin size="s">
                <Link aria-label={title} href={url}>
                  {title}
                </Link>
              </Body>
            </li>
          )
        )}
        <li className={`iubenda-cs-preferences-link ${footerPrivacyTermsListItem}`}>
          <Body noMargin size="s">
            Cookie Preferences
          </Body>
        </li>
        <li className={footerPrivacyTermsListItem}>
          <Body noMargin size="s">
            <Link aria-label="compliance" href={"/compliance"}>
              Compliance
            </Link>
          </Body>
        </li>
      </ul>

      <Body noMargin size="s">
        {title} {vat && `– ${vat}`}
      </Body>
    </div>
  )
}
